import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import Footer from './Footer'
import Navbar from './Navbar'

import '../../styles/all.sass'

const TemplateWrapper = ({ children, title, description }) => {
  return (
    <Fragment>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="theme-color" content="#fff" />
        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta property="og:image" content="/img/og-image.jpg" />
      </Helmet>
      <Navbar />
      <main>{children}</main>
      <Footer />
    </Fragment>
  )
}

export default TemplateWrapper
