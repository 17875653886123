import React, { useState } from 'react'
import classNames from 'classnames'
import { Link, graphql, StaticQuery } from 'gatsby'

const Navbar = ({ title, logo, items }) => {
  const [collapsed, setCollapsed] = useState(false)

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-dark">
        <div className="container">
          <div className="w-100 d-lg-block d-flex justify-content-between">
            <div className="navbar-brand float-left d-block">
              <Link to={'/'}>
                <img className="rounded-circle" alt={title} src={logo} />
                {title}
              </Link>
            </div>
            <button
              className={classNames('navbar-toggler collapsed float-right', {
                show: collapsed,
              })}
              onClick={() => setCollapsed(!collapsed)}
              type="button"
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className={classNames(
                'navbar-collapse collapse navbar-toggler-right',
                { show: collapsed }
              )}
            >
              <ul className="navbar-nav ml-auto justify-content-lg-end w-100">
                {items.map(({ link, label }) => (
                  <li key={link} className="nav-item">
                    <Link
                      to={link}
                      exact={link === '/'}
                      className="nav-link"
                      activeClassName="active"
                    >
                      {label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default () => (
  <StaticQuery
    query={graphql`
      {
        layoutYaml(name: { eq: "Navbar" }) {
          name
          title
          logo
          items {
            link
            label
          }
        }
      }
    `}
    render={data => <Navbar {...data.layoutYaml} />}
  />
)
