import React from 'react'
import { Link } from 'gatsby'
import classNames from 'classnames'
import Markdown from 'markdown-to-jsx'

// import Contact from '../Contact';
// import Subscribe from '../Subscribe';
// import YouTube from './YouTube';

// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
// import {library} from '@fortawesome/fontawesome-svg-core';
// import {faReact, faNodeJs} from '@fortawesome/free-brands-svg-icons';
// import {
//   faMagic,
//   faMobileAlt,
//   faRunning,
//   faCode,
//   faThumbsUp,
// } from '@fortawesome/free-solid-svg-icons';
//
// library.add(
//   faCode,
//   faMobileAlt,
//   faReact,
//   faRunning,
//   faMagic,
//   faNodeJs,
//   faThumbsUp,
// );

const Button = ({ to, outline, children }) => (
  <Link
    exact="true"
    to={to}
    prefetch={false}
    className={classNames(`btn btn-lg btn${outline ? '-outline' : ''}-primary`)}
  >
    {children}
  </Link>
)

const Row = ({ bg, center, children }) => (
  <div
    className={classNames('row align-middle p-sm-1 p-md-5', {
      'text-center': center,
      'bg-inverse text-white': bg,
    })}
    style={{ backgroundImage: `url(${bg})` }}
  >
    {children}
  </div>
)

const LinkOrDiv = ({ url, children, ...props }) =>
  url ? (
    <a href={url} {...props}>
      {children}
    </a>
  ) : (
    <div {...props}>{children}</div>
  )

const Col = col => ({ bg, url, children }) => (
  <LinkOrDiv
    url={url}
    className={classNames(
      `col-lg-${col} col-md-${col === 2 ? '6' : '12'} p-3`,
      {
        'bg-inverse text-white': bg,
      }
    )}
    style={{ backgroundImage: `url(${bg})` }}
  >
    {children}
  </LinkOrDiv>
)

export default ({ children }) => (
  <Markdown
    options={{
      forceBlock: true,
      overrides: {
        Button,
        Col2: Col(2),
        Col4: Col(4),
        Col6: Col(6),
        Col9: Col(9),
        Col12: Col(12),
        Row,
      },
    }}
    children={children || ''}
  />
)
