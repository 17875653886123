import React from 'react'
import { ErrorBoundary } from 'react-error-boundary'

const FallbackComponent = ({ componentStack, error }) => (
  <div>
    <p>
      <strong>Oops! An error occured!</strong>
    </p>
    <p>
      <strong>Error:</strong> {error.toString()}
    </p>
    <p>
      <strong>Stacktrace:</strong> {componentStack}
    </p>
  </div>
)

export default ({ children, classes }) => (
  <section className={`page-section ${classes}`}>
    <div className="container">
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        {children}
      </ErrorBoundary>
    </div>
  </section>
)
