import React from 'react'
import { graphql, StaticQuery } from 'gatsby'

const Footer = ({ copyright, socialmedia: { text, items } }) => (
  <footer className="bg-dark text-center d-print-none">
    <div className="pt-3 text-large">{text}</div>
    <div className="social-media p-3">
      {items.map(({ icon, link }) => (
        <a href={link}>
          <i className={`fa fa-${icon}`} />
        </a>
      ))}
    </div>
    <div className="pb-3">{copyright}</div>
  </footer>
)

export default () => (
  <StaticQuery
    query={graphql`
      {
        layoutYaml(name: { eq: "Footer" }) {
          socialmedia {
            text
            items {
              icon
              link
            }
          }
          copyright
        }
      }
    `}
    render={data => <Footer {...data.layoutYaml} />}
  />
)
