import React, { Fragment } from 'react'
import classNames from 'classnames'

import PageContent from '../content/PageContent'

const HeroBackgroundVideo = ({ video_webm, video_mp4 }) => (
  <video className="hero__video" loop autoPlay muted preload="auto">
    {video_webm && <source src={video_webm} type="video/webm" />}
    {video_mp4 && <source src={video_mp4} type="video/mp4" />}
  </video>
)

export default ({
  text,
  background,
  image,
  classes,
  center,
  video_webm,
  video_mp4,
}) => {
  const hasVideo = video_webm || video_mp4

  return (
    <section
      className={classNames(`hero ${classes}`, {
        '-center': center,
      })}
    >
      <figure>
        <img
          className="hero__background"
          src={
            background && !!background.childImageSharp
              ? background.childImageSharp.fluid.src
              : background
          }
        />
        {hasVideo && (
          <HeroBackgroundVideo video_webm={video_webm} video_mp4={video_mp4} />
        )}
        <figcaption className="hero__container">
          <div className="container d-flex align-items-center">
            <figure>
              <PageContent children={text} />
            </figure>
            {image && (
              <img
                alt={``}
                className="hero__image"
                src={
                  !!image.childImageSharp
                    ? image.childImageSharp.fluid.src
                    : image
                }
              />
            )}
          </div>
        </figcaption>
      </figure>
    </section>
  )
}
